@import "./colors.scss";

.nav-bar {
  display: flex;
  justify-content: space-between;
  padding: 0rem $main_padding;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
}
.nav_logo {
  width: 60px;
}
.nav_toggle_btn {
  display: none;
  button {
    font-size: 2rem;
    padding: 0px 10px;
    cursor: pointer;
    background-color: transparent;
    border: 0px;
    color: $primary_color;
  }
}
.nav_links {
  display: flex;
  align-self: stretch;
  align-items: stretch;
  gap: 1.7rem;
}
.nav_link_con {
  a {
    color: #000;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-size: 0.9375rem;
    font-style: normal;
    line-height: 1.5rem; /* 160% */
    letter-spacing: 0.01875rem;
    text-decoration: none;
    font-weight: 400;
    svg {
      margin-left: 0.5rem;
    }
  }
}
.nav_link_con_link {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 2rem 0;
}
.nav_link_con:hover {
  .nav_link_con_link {
    color: $primary_color;
  }
  .nav_link_con_list {
    display: flex;
  }
}
.nav_link_con_list {
  position: absolute;
  top: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 15px 0px;
  left: 0;
  width: 100%;
  background-color: $main_back_color;
  display: none;
  z-index: 10;
  padding: 1rem $main_padding;

  img {
    width: 20%;
  }
}
.nav_link_con_list_links {
  display: flex;
  gap: 2rem;
  .nav_l_c_l_l_con {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-left: calc($main_padding/2);
    flex-grow: 1;
  }
}
.nav_l_c_l_l_con a:hover {
  color: $primary_color;
}
.nav_btn {
  display: flex;
  gap: 1rem;
  a {
    padding: 1rem;
    border: 1px solid;
    text-decoration: none;
    color: #000;
  }
}
.nav_btn a:hover {
  background-color: $primary_color;
  color: #fff;
  transition: 0.2s;
}
.nav_link_con_list_toggle {
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  a {
    padding: 0.5rem 1rem;
  }
}
@media (min-width: 1440px) {
  .nav-bar a {
    font-size: 1vw;
  }
}
@media (max-width: 1080px) {
  .nav-bar {
    padding: 1rem 3rem;
  }
}
.nav_toggle_links {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: inherit;
  width: 100vw;
  height: 80vh;
  padding: 1rem;
  overflow-y: auto;
  display: none;
  .nav_link_con {
    text-align: center;
  }
  .nav_link_con_link {
    padding: 1rem;
    text-align: center;
  }

  .nav_btn {
    margin: 2rem 1rem;
    flex-direction: column-reverse;
    a {
      text-align: center;
      background-color: $primary_color;
      color: #fff;
    }
  }
}
.toggleNav {
  background-color: #000;
  a,
  .nav_link_con_link {
    color: #fff !important;
    text-align: left;
  }

  .nav_toggle_btn button {
    color: #fff;
  }
}
.toggleNav .nav_toggle_links {
  display: block !important;
}
@media (max-width: 992px) {
  .nav_links,
  .nav_btn {
    display: none;
  }
  .nav_toggle_links .nav_links {
    display: block;
  }
  .nav_toggle_links .nav_btn {
    display: flex;
  }
  .nav_toggle_btn {
    display: block;
  }
  .nav_link_con a:hover,
  .nav_link_con_link:hover {
    color: #fff !important;
  }
}
@media (max-width: 600px) {
  .nav-bar {
    padding: 1rem;
  }
}
