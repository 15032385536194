@import "./colors.scss";

.primaryBTN {
  display: inline-flex;
  padding: 0.875rem 1.875rem;
  justify-content: center;
  align-items: center;
  background-color: $primary_color;
  color: #fff;
  font-family: "Inter";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;

  img {
    width: 1rem;
    margin-left: 0.5rem;
  }
}

/*-- how it works section --*/
.how_it_works {
  background: linear-gradient(180deg, #dadaff 0%, rgba(218, 218, 255, 0) 100%);
  padding-bottom: 0 !important;
}
.h_i_w_topInfo {
  width: 80%;
  margin: auto;
  margin-bottom: $main_padding;
  text-align: center;

  p {
    font-family: "Inter";
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 160%;
  }
}
.h_i_w_bottomInfo {
  width: 85%;
  margin: auto;
}

.h_i_w_b_text {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.h_i_w_b_text_item {
  width: 25%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  .h_i_w_b_t {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 1.5rem 0;
    p {
      text-align: center;
    }
  }
  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin-top: 1rem;
    color: $primary_color;
    font-family: "Metropolis", Arial, Helvetica, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: bold;
    line-height: 150%;
    text-decoration: none;
    display: flex;
    align-items: center;

    img {
      width: 1.5em;
      margin-left: 0.5em;
    }
  }
  p {
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.04rem;
    font-family: "Inter";
  }
  p.title {
    color: #000;
    font-size: 1.5625rem;
    font-weight: 600;
    line-height: normal;
    font-family: "Inter";
    margin-bottom: 1rem;
    text-align: center;
    height: 2rem;
    max-height: 6.5rem;
  }
}

@media screen and (max-width: 1400px) {
  .h_i_w_b_text_item {
    p.title {
      height: 3.5rem;
      max-height: 6rem;
    }
  }
}
@media screen and (max-width: 900px) {
  .h_i_w_b_text_item {
    p.title {
      height: 5rem;
      max-height: 6rem;
    }
  }
}
@media screen and (max-width: 600px) {
  .h_i_w_b_text_item {
    p.title {
      height: 2rem;
      max-height: 6rem;
    }
  }
}
.h_i_w_b_img {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-right: 10%;

  .h_i_w_b_i {
    width: 100px;
    position: relative;
    height: 100px;
    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%;
      background-color: $back_light;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000;
      font-size: 2rem;
      font-weight: 800;
    }
    img {
      width: 100%;
    }
  }

  .h_i_w_arrow {
    width: 30%;
    @extend %center_by_flex;
    img {
      width: 100%;
    }
  }
}
.h_i_w_b_t {
  width: 90%;
}

/* -- Box --*/
.box {
  padding: $main_padding 0;
}

/*-- features_list --*/
.features_list {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: auto;
}
.features_list_item {
  width: 31%;
  min-width: 300px;
  display: flex;
  padding: 1.125rem 1.375rem;
  align-items: flex-start;
  gap: 1.25rem;
  border: 1px solid #ddd;

  .feature_item_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  h3 {
    font-family: "Inter";
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 160%; /* 1.8rem */
    letter-spacing: -0.045rem;
  }

  p {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    color: #666;
  }

  button {
    color: #999;
    background: #fff;
    border: 0;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.375rem;
    svg {
      opacity: 0;
      transition: 0.2s;
    }
  }
  button:hover {
    color: $primary_color;
    transition: 0.2s;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    svg {
      opacity: 1;
    }
  }
}
.feature_all_link {
  background-color: $main_back_color;
  border: 0;
  @extend %center_by_flex;

  a {
    display: flex;
    gap: 1.25rem;
    color: $primary_color;
    align-items: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: -0.045rem;
    cursor: pointer;
    text-decoration: none;
  }
}
.feature_popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  @extend %center_by_flex;
  z-index: 10;
}
.feature_popup_item {
  background-color: #fff;
  width: 90%;
  padding: calc($main_padding / 4);

  p {
    color: #464646;
    margin-top: 1rem;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
  }
}
.feature_popup_item_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    // white-space: nowrap;
    width: 100%;
  }
  .feature_popup_item_top_con {
    width: 100%;
    .h_i_w_b_img {
      max-width: 300px;
    }
  }
  div {
    display: flex;
    align-items: center;
    gap: 1rem;
    // width: 60px !important;
  }
  .h_i_w_arrow {
    display: none !important;
    img {
      display: none !important;
    }
    width: 0 !important;
  }
  .h_i_w_b_i {
    p {
      margin: 0 !important;
    }
  }
  svg {
    cursor: pointer;
  }
}

/*-- page title --*/
.page_title {
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 0rem 0rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  // background: linear-gradient(180deg, #dbe1ff 0%, #f1f4ff 100%);
  h1 {
    font-weight: 900;
    text-align: center;
    font-size: 2.5rem;
    font-style: normal;
    line-height: 111%;
    color: $primary_color;
  }
  p {
    text-align: center;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 600;
    line-height: 111%;
    opacity: 0.5;
  }
}
.pad {
  padding-bottom: 0 !important;
}
.page_description {
  width: 90%;
  margin: auto;
  h2 {
    color: #000;
    font-family: "Metropolis", Arial, Helvetica, sans-serif;
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    margin-bottom: 1rem;
  }
  p {
    // color: #6a6a6a;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 300;
    line-height: 160%;
  }
}

/* -- try_bottom --*/
.try_bottom {
  text-align: center;
  width: 90%;
  margin: auto;
  // margin-top: $main_padding;
  p {
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 1.8rem */
    letter-spacing: -0.045rem;
  }
}
.try_links {
  @extend %center_by_flex;
  margin-top: 2rem;
  flex-wrap: wrap;

  a {
    text-decoration: none;
    align-items: flex-start;
    gap: 0.625rem;
    display: flex;
    padding: 1.125rem 1.875rem;
  }
  gap: 1rem;
  a:first-child {
    background-color: $primary_color;
    color: #fff;
  }
  a:last-child {
    background-color: $back_light;
    color: $primary_color;
  }
}
.try_bottom_back {
  background-color: $main_back_color;
}

/*-- apps --*/
.apps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3.5rem;
  width: 95%;
  margin: auto;
}
.apps_item {
  width: 45%;
  display: flex;
  align-items: center;
  gap: 2rem;

  .a_i_img {
    min-width: 7.5rem;
    min-height: 7.5rem;
    @extend %center_by_flex;
    border-radius: 50%;
    border: 1px solid $primary_color;
  }
}
.a_i_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  h3 {
    color: #000;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  p {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: lighter;
    line-height: 150%; /* 1.5rem */
    letter-spacing: -0.04rem;
    opacity: 0.6;
  }
}

/*-- list_square_items --*/
.list_square_items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2.5rem;
  width: 90%;
  margin: auto;
}
.l_s_item {
  border: 1px solid #e2e2e2;
  text-align: center;
  min-width: 250px;
  h3 {
    color: #262626;
    text-align: center;
    font-size: 1.1875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 1.9rem */
    letter-spacing: -0.0475rem;
  }

  p {
    color: #000;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 100;
    line-height: 160%; /* 1.6rem */
    letter-spacing: -0.04rem;
    opacity: 0.5;
  }

  .l_s_i_text {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem;
  }
}
.img_full_width {
  width: 100%;
  max-height: 200px;
  overflow: hidden;
  @extend %center_by_flex;

  img {
    width: 100%;
  }
}

/*-- image_text --*/
.image_text {
  display: flex;
  padding: $main_padding;
  gap: 4rem;
  align-items: center;
}
.i_t_text {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;

  h2 {
    margin: 0;
  }
  p {
    color: #1e1e1e;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }
}
.i_t_img_text {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  div {
    width: fit-content;
    position: relative;
    width: 80%;
    img {
      display: flex;
      position: relative;
      z-index: 1;
      width: 100%;
    }
  }

  svg {
    display: none;
  }
}
.backBorder {
  div::after {
    content: "";
    width: 100%;
    height: 100%;
    border: 0.3rem solid $primary_color;
    position: absolute;
    top: -2rem;
    right: -2rem;
    z-index: 0;
  }
  svg {
    position: absolute;
    top: 25%;
    left: calc(100% + 3rem);
    z-index: 0;
    font-size: 10vw;
    display: block;
  }
}
.i_t_links {
  display: flex;
  gap: 1rem;
  a:last-child {
    display: inline-flex;
    padding: 1.125rem 1.875rem;
    align-items: center;
    gap: 0.625rem;
    color: $primary_color;
    background-color: #dadaff;
    text-decoration: none;
  }
  a:first-child {
    display: inline-flex;
    padding: 1.125rem 1.875rem;
    align-items: center;
    gap: 0.625rem;
    color: #fff;
    background-color: $primary_color;
    text-decoration: none;
  }
}
.image_text.image_text_left {
  flex-direction: row-reverse;
  align-items: center;
  .i_t_img_text {
    justify-content: flex-start;
  }
}

/*-- pricing --*/
.toggle_year {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 3rem;
}
.pricing_card {
  display: flex;
  gap: 1rem;
  width: 80%;
  margin: auto;
}
.pricing_card_item {
  width: 50%;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #dedede;
  justify-content: space-between;
  align-items: center;
  div {
    width: 100%;
    justify-content: center;
    text-align: center;
  }
  h2 {
    margin: 0 !important;
    text-align: center;
  }
  h3 {
    font-weight: 800;
    text-align: center;
    font-family: "Metropolis", Arial, Helvetica, sans-serif;
    font-size: 1.9rem;
    font-style: normal;
    line-height: 1.75rem;
    text-align: center;
  }
  p {
    width: 80%;
    text-align: center;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem;
    margin: auto;
  }
}
.pricing_card_item.active {
  background-color: $primary_color;
  color: #fff;
  h2 {
    color: #fff;
  }
}
.pricing_card_item:last-child {
  background-color: rgb(218, 218, 255);
}
.pricing_card_count {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  p {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
  }

  h2 {
    flex-grow: 1;
    width: 50%;
    text-align: right;
    font-size: 2.5rem !important;
  }

  a {
    text-decoration: none;
    color: $primary_color;
    border: 1px solid;
    padding: 0.5rem 1rem;
    font-size: 1.5rem !important;
  }
}
.pricing_list {
  width: 80%;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
}
.pricing_list_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  min-height: 3.83463rem;

  p {
    flex-grow: 1;
    text-align: center;
    color: var(--Black, #282828);
    text-align: center;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  svg {
    opacity: 1;
  }
  .pricing_list_item_title {
    flex-grow: 0;
    text-align: left !important;
    width: 30%;
    color: #000 !important;
    text-align: center;
    font-family: "Metropolis", Arial, Helvetica, sans-serif;
    font-size: 1.0805rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.86081rem; /* 172.222% */
    letter-spacing: 0.01081rem;
  }
}
.pricing_list_item:nth-child(odd) {
  background-color: #f4f4f4;
}
.pricing_list a {
  width: 100%;
  background-color: $primary_color;
  color: #fff;
  text-decoration: none;
  text-align: center;
  padding: 1.5rem;
  margin: 2rem auto;
}

/*-- responsive --*/
@media (min-width: 1440px) {
  .primaryBTN {
    font-size: 0.8vw;
  }
  .h_i_w_b_text_item {
    p,
    button {
      font-size: 1vw;
    }
    p.title {
      font-size: 1.3vw;
      height: 4rem;
    }
    .h_i_w_b_i {
      p {
        font-size: 2vw;
      }
    }
  }
  .h_i_w_topInfo {
    p {
      font-size: 1vw;
    }
  }
  .features_list_item h3,
  .feature_popup h3 {
    font-size: 1.1vw;
  }
  .features_list_item {
    h3 {
      font-size: 1.1vw;
    }
    p {
      font-size: 1vw;
    }
    button {
      font-size: 0.8vw;
    }
    button:hover {
      font-size: 0.8vw;
    }
  }

  .feature_item_img {
    width: 5vw;
    img {
      width: 5vw;
    }
  }

  .try_bottom p,
  .try_links a,
  .page_description p,
  .page_title p,
  .feature_all_link a,
  .feature_popup p,
  .l_s_item p,
  .i_t_text p,
  .i_t_text a,
  .pricing_list p,
  .toggle_year p,
  .pricing_card p,
  .pricing_list a {
    font-size: 1vw;
  }
  .page_description h2 {
    font-size: 1.5vw !important;
  }
  .l_s_item h3,
  .pricing_list_item_title {
    font-size: 1.2vw !important;
  }
}

.price_toggle {
  border: 2px solid #8d8cc4;
  width: 4rem;
  padding: 0.2rem;
  border-radius: 3rem;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  transition: 1s;

  p {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #8d8cc4;
  }
}
.how_it {
  .i_t_text {
    justify-content: flex-start;
  }
}

@media (max-width: 992px) {
  .image_text {
    padding: 3rem 0;
    width: 90%;
    margin: auto;
  }
  .h_i_w_bottomInfo {
    width: 90%;
  }
  .how_bottom {
    // padding: 0 3rem;
    overflow: hidden;
    background-color: rgb(237, 237, 237);
  }
  .aaa {
    position: relative;
    min-width: 4.5rem;
    min-height: 4.5rem;
  }
  .apps_item .a_i_img {
    min-width: 4.5rem;
    min-height: 4.5rem;
    svg {
      width: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .i_t_links {
    flex-wrap: wrap;
  }
  .i_t_text a:last-child,
  .i_t_text a:first-child {
    width: 100%;
  }

  .pricing_card_item h3 {
    font-size: 1.5rem;
  }
}

@media (max-width: 600px) {
  .pricing_card_item h3 {
    font-size: 1.2rem;
  }
  .i_t_links {
    flex-direction: column;
    a {
      width: 100% !important;
    }
  }
  .feature_all_link {
    background-color: #fff;
    border: 1px solid $primary_color;
  }
  .how_it_works {
    // padding-bottom: 0 !important;
  }
  .use_cases_slide {
    padding-top: 0rem !important;
  }
  .page_title {
    padding-bottom: 0 !important;
    h1 {
      font-size: 1.7rem;
    }
    p {
      font-size: 0.7rem;
    }
  }
  .box {
    padding: 2rem 0;
    p {
      font-size: 1rem !important;
    }
    h2 {
      font-size: 1.5rem;
      letter-spacing: 0.0001rem;
      line-height: 110%;
      margin-bottom: 1rem;
    }
  }
  .features_list_item {
    width: 100%;
    min-width: 200px;
    padding: 0.5rem;
    h3 {
      font-size: 1rem;
    }
  }
  .feature_popup_item_top_con {
    flex-direction: column;
    h3 {
      flex-grow: 1;
    }
  }
  .light_link_see {
    div {
      width: 90vw;
    }
  }
  .features_list_item p {
    font-size: 0.8rem !important;
  }
  .try_links {
    a {
      width: 100%;
      justify-content: center;
      align-items: center;
      font-size: 0.9rem;
      padding: 0.5rem;
    }
  }
  .try_bottom {
    p {
      text-align: left;
    }
  }
  .l_s_item {
    width: 100% !important;
  }
  .l_s_i_text {
    padding: 0.5rem !important;
  }
  .pricing_card,
  .pricing_list {
    flex-wrap: wrap;
    width: 90%;
  }
  .pricing_card_item {
    width: 100%;
  }
  .toggle_year {
    margin: 1rem;
  }
  .price_toggle p {
    width: 1rem;
    height: 1rem;
  }
  .price_toggle {
    width: 3rem;
  }
  .pricing_list_item {
    padding: 1rem;
  }
  .pricing_list_item p {
    font-size: 0.8rem !important;
  }
  .pricing_list_item p.pricing_list_item_title {
    font-size: 0.8rem !important;
  }
  .pricing_list_item svg {
    width: 30px;
  }
  .pricing_list a {
    padding: 1rem;
  }
  .image_text {
    flex-direction: column;
    gap: 2rem;
  }
  .h_i_w_b_img {
    justify-content: center;
    padding: 0 10%;
  }
  .i_t_text,
  .i_t_img_text,
  .i_t_img_text div,
  .h_i_w_b_text_item {
    width: 100%;
  }
  .h_i_w_b_text {
    flex-direction: column;
  }
  .h_i_w_b_img .h_i_w_arrow {
    display: none !important;
  }
  .h_i_w_b_img .h_i_w_b_i p {
    font-size: 1.5rem !important;
  }
  .h_i_w_topInfo {
    width: 90%;
    text-align: left;
    margin-bottom: 1rem;
    h2 {
      font-size: 1.5rem;
      line-height: 1.6rem;
    }
  }
  .i_t_text h2 {
    font-size: 1.5rem;
  }
  .h_i_w_b_text_item p {
    font-weight: 300;
  }
  .h_i_w_b_text_item .h_i_w_b_t {
    margin: 1rem 0;
  }
  .h_i_w_b_text_item button {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  .apps_item {
    width: 100%;
  }

  .apps_item {
    gap: 1rem;
  }
  .a_i_text h3 {
    font-size: 1.1rem;
  }
  .a_i_text p {
    font-size: 0.9rem !important;
  }
  .apps {
    margin-top: 2rem;
  }
  .powerful_apps .light_link {
    margin: 2rem auto 0 auto !important;
    width: 90%;
  }
  .image_text.image_text_left {
    flex-direction: column-reverse;
    overflow: hidden;
  }
  .i_t_text {
    gap: 1rem;
  }
  .i_t_links {
    flex-wrap: nowrap;
    gap: 0.5rem;
  }
  .i_t_text a:last-child,
  .i_t_text a:first-child {
    width: 50%;
    padding: 0.5rem !important;
    font-size: 0.8rem;
    gap: 0.3rem !important;
    justify-content: space-between;
    white-space: nowrap;
  }
  .h_i_w_b_t {
    width: 100%;
  }
}

.gif_icons {
  img {
    width: 100px;
  }
}
