$primary_color: #051d8d;
$text_color: #1e1e1e;
$main_back_color: #f8f8f8;
$back_light: #dadaff;

$main_padding: 4rem;

%center_by_flex {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
