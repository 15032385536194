@import "./colors.scss";

/*-- header --*/
.homepage {
  background-color: $main_back_color;
}
.unlock_the_power {
  background-color: #fff;
}

.home_header {
  max-width: 100vw;
  height: 100vh;
  background: linear-gradient(76deg, #000 20.78%, rgba(0, 0, 0, 0.31) 61.97%),
    url("../assets/images/pexels-benoit-dujardin-80608394-8756809.jpg"),
    lightgray 0px -10.06px / 102.5% 140.589% no-repeat;
  // background: ;
  background-size: cover;
  background-position: top center;
  position: relative;
}
.home_header_text {
  width: 50%;
  height: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10%;

  font-family: "Inter", Arial, Helvetica, sans-serif;

  h1 {
    font-size: 3.125rem;
    font-weight: 600;
    letter-spacing: -0.125rem;
  }

  p {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 160%;
    margin-top: 0.5rem;
  }
}

.h_h_t_links {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;

  a:last-child {
    text-decoration: none;
    color: #000;
    background-color: #fff;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;

    display: inline-flex;
    padding: 0.875rem 1.875rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
  }
}

.scroll_down {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0%);
  button {
    background-color: transparent;
    border: 0;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 1rem;
    cursor: pointer;

    img {
      display: block;
      position: absolute;
      top: calc(100% + 1rem);
      left: 50%;
      transform: translate(-50%, -50%);
      animation: arrowBottom 1s linear alternate-reverse infinite;
      width: 1rem;
    }
  }
}
@keyframes arrowBottom {
  100% {
    top: calc(100% + 2rem);
  }
}

/*-- what is shasha --*/
.what_is_shasha {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
  background-color: $main_back_color;
}
.w_i_s_text {
  width: 50%;
  padding-left: 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  p {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }
}
.w_i_s_img {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  max-width: 40vw;
  max-height: 500px;
  div {
    height: 100%;
    max-width: 40vw;
    position: relative;
    min-width: 300px;
  }
  img {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  div::after {
    content: "";
    border: 5px solid $primary_color;
    position: absolute;
    top: -2rem;
    right: -2rem;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}

/*-- player options --*/
.player_options {
  display: flex;
  padding: $main_padding calc($main_padding);
  background: linear-gradient(180deg, #dadaff 0%, #ededed 100%);
}
.player_options_text {
  width: 50%;

  h2 {
    // color: #1e1e1e;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.0625rem;
    margin: 0;
  }
  p {
    // color: #6a6a6a;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 300;
    line-height: 160%;
    margin: 2rem 0;
  }
}
.light_link {
  display: flex;
  width: 12.25rem;
  height: 3.75rem;
  padding: 0.6875rem 1.5rem;
  justify-content: center;
  align-items: center;
  border: 1px solid $primary_color;
  color: $primary_color;
  text-decoration: none;
}
.player_options_imgs {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: right;
  img {
    min-width: 50%;
    max-width: 90%;
    object-fit: contain;
  }
}

/*-- powerful_apps --*/
.powerful_apps {
  background-color: $main_back_color;
  .light_link {
    margin: 3rem auto 0 auto;
  }
}

/*--home_pricing --*/
.home_pricing {
  background: linear-gradient(180deg, #dadaff 0%, rgba(218, 218, 255, 0) 100%);
  .h_i_w_topInfo {
    margin-bottom: 0;
  }
}
.home_pricing_Info {
  text-align: center;
  h2 {
  }
}

@media (min-width: 1440px) {
  .home_header_text {
    h1 {
      font-size: 3vw;
    }
    p {
      font-size: 1vw;
    }
  }

  .h_h_t_links {
    a:last-child {
      font-size: 0.8vw;
    }
  }
  .scroll_down {
    button {
      font-size: 1vw;

      img {
        width: 2vw;
      }
    }
  }

  .w_i_s_text {
    p {
      font-size: 1vw;
    }
  }

  .player_options_text {
    p {
      font-size: 1vw;
    }
  }
  .light_link {
    font-size: 0.8vw;
  }
}

@media (max-width: 992px) {
  .home_header_text {
    width: 80%;
  }
  .what_is_shasha {
    margin-left: 5%;
  }
  .w_i_s_text {
    padding: 0;
  }
  .w_i_s_text p {
    font-size: 1rem !important;
  }

  .home_header_text {
    width: 90%;
    margin: auto;
  }
  .player_options {
    padding: 5%;
    h2 {
      font-size: 1.5rem;
    }
    p {
      font-size: 1rem;
      margin: 1rem 0;
    }
  }
}
@media (max-width: 600px) {
  .home_header_text h1 {
    font-size: 2rem;
    letter-spacing: 0.1rem;
  }
  .h_h_t_links {
    flex-wrap: wrap;
  }
  .h_h_t_links a {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem !important;
  }
  .h_h_t_links a:last-child {
    align-items: flex-start;
  }
  .what_is_shasha {
    margin: auto;
    width: 90%;
    flex-direction: column;
  }
  .w_i_s_text {
    width: 100%;
  }
  .w_i_s_img_con {
    // width: 100vw;
  }
  .w_i_s_img div,
  .w_i_s_img_con {
    min-width: 80vw;
    max-width: 80vw;
    margin: auto;
  }
  .w_i_s_img {
    width: 85vw;
    margin-top: 3rem;
    max-width: 100vw;
  }
  .w_i_s_img div::after {
    top: -1rem;
    right: -1rem;
  }
  .player_options {
    flex-direction: column;
  }
  .player_options_text,
  .player_options_imgs {
    width: 100%;
    img {
      width: 80%;
      margin-top: 2rem;
    }
  }
  .player_options_imgs {
    img {
      width: 100%;
    }
  }
  .light_link {
    padding: 0.5rem 3rem;
    height: auto;
    width: 100%;
  }
  .home_pricing .light_link {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  .home_pricing_Info {
    text-align: left;
    padding: 0 5%;
    h2 {
      margin: 1rem 0;
    }
  }

  .player_options_imgs {
    text-align: center;
    img {
      min-width: 50%;
      max-width: 90%;
      object-fit: contain;
    }
  }
}
