@import "./colors.scss";
.contact_items {
  position: relative;
  width: 80%;
  margin: auto;
}
.contact_items_con {
  // position: absolute;
  // top: 50%;
  // transform: translate(0, -50%);
  display: flex;
  justify-content: center;
  gap: 3rem;
  width: 100%;
}
.contact_i {
  background-color: #fff;
  display: flex;
  padding: 2rem;
  gap: 2rem;
  box-shadow: 15px 30px 45px 0px rgba(76, 94, 89, 0.1);
  width: 30%;

  h3 {
    margin-bottom: 2rem;
  }
  a {
    color: #000;
    text-decoration: none;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-weight: 300;
  }
  p {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-weight: 300;
  }
}
.contact_form {
  display: flex;
  width: 80%;
  margin: auto;
  gap: 2rem;

  form {
    width: 50%;

    p {
      color: $primary_color;
      margin-bottom: 1rem;
    }
    h2 {
      color: $primary_color;
      font-family: "Metropolis", Arial, Helvetica, sans-serif;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      // padding: 1rem 2rem;
      box-shadow: 0px 14px 58px 0px rgba(117, 125, 153, 0.08);
    }
    input,
    textarea {
      padding: 1rem 1rem 1rem 0;
      border: 0;
      border-bottom: 1px solid $back_light;
      resize: vertical;
    }
    button {
      align-self: flex-start;
      padding: 1rem;
      border: 0;
      background-color: $primary_color;
      color: #fff;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-weight: bold;
      cursor: pointer;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      font-size: 1rem;
    }
  }
}
.contact_img {
  width: 50%;
  position: relative;
  overflow: hidden;
  @extend %center_by_flex;

  img {
    position: absolute;
    width: 100%;
  }
}

.contact_map {
  width: 100%;
  position: relative;

  img {
    width: 100%;
    display: flex;
  }

  .contact_map_text {
    position: absolute;
    top: 40%;
    left: 30%;
    background-color: #fff;
    border-radius: 0 5px 5px 5px;

    p {
      font-family: "Inter", Arial, Helvetica, sans-serif;
      font-weight: 300;
      margin: 2rem;
      display: flex;
      gap: 1rem;

      strong {
        color: $primary_color;
      }
    }

    div {
      position: absolute;
      bottom: calc(100% + 1rem);
      width: 50px;
      height: 50px;
      @extend %center_by_flex;
      left: 0;
      background-color: #fff;
      border-radius: 50%;
    }
  }
}
.form_radio {
  label {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
}

@media (max-width: 992px) {
  .contact_items {
    width: 90%;
  }
  .contact_i {
    padding: 1rem;
    width: 33%;
  }
  .contact_i h3 {
    margin-bottom: 1rem;
  }
  .contact_items_con {
    gap: 2rem;
  }
  .contact_form {
    flex-direction: column;
  }
  .contact_margin {
    display: none;
  }
  .contact_img img {
    position: relative;
  }
  .contact_img,
  .contact_form form {
    width: 80%;
    margin: auto;
  }
  .contact_map .contact_map_text {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 250px;
  }
  .contact_map {
    min-height: 400px;
    overflow: hidden;
  }
  .contact_map img {
    height: 100%;
    width: auto;
  }
  .contact_map .contact_map_text p {
    margin: 1rem;
  }
  .contact_map .contact_map_text div {
    bottom: calc(100% + 0.5rem);
    width: 40px;
    height: 40px;
    svg {
      width: 60%;
    }
  }
  .contact_form form h2 {
    font-size: 1.5rem;
  }
  .contact_form form div {
    // padding: 1rem;
  }
  .contact_form form button {
    padding: 0.5rem 1rem;
  }
}

@media (max-width: 600px) {
  .contact_items_con {
    flex-direction: column;
  }
  .contact_i {
    width: 100%;
  }
  .contact_items_con {
    position: relative;
    transform: translate(0, -10%);
  }
  .contact_img,
  .contact_form form {
    width: 100%;
    margin: auto;
  }
}
