@import "./colors.scss";

.footer_top {
  padding: calc($main_padding / 2) $main_padding;
  display: flex;
  border-top: 1px solid rgba(30, 30, 30, 0.08);
  border-bottom: 1px solid rgba(30, 30, 30, 0.08);
  padding-bottom: 1rem !important;
  padding-left: 1rem !important;
  h4 {
    margin-bottom: 2rem;
    text-transform: capitalize;
    font-family: "Inter", Arial, Helvetica, sans-serif;
  }
}
.footer_larg {
  width: 40%;
}
.footer_links_CON {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.footer_links {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  // align-items: center;
  width: 100%;

  .footer_links_con {
    display: flex;
    // flex-direction: column;
    gap: 0rem 4rem;
    div {
      display: flex;
      flex-direction: column;
      gap: 5px;
      color: #000;
    }
    a {
      font-family: "Inter", Arial, Helvetica, sans-serif;
      text-decoration: none;
      color: #000 !important;
      font-weight: 400;
    }
  }
}
.footer_links a:hover {
  color: $primary_color;
  opacity: 1;
}
.footer_logo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // gap: 3rem;
  // align-items: center;
}
.footer_logo_img {
  svg {
    width: 60px !important;
  }
  p {
    color: #1e1e1e;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    width: 80%;
    line-height: 1.5rem;
    margin-top: 2rem;
  }
}
.footer_logo_bottom {
  p {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 1rem;
  }
  .footer_logo_bottom_con {
    width: 50%;
    display: flex;
    align-items: baseline;
    gap: 10px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.footer_bottom {
  text-align: center;
  padding: 1rem;
  a {
    text-decoration: none;
    color: $primary_color;
  }
}
.footer_conatct h4 {
  margin-left: 13px;
}
.footer_conatct a:last-child {
  margin-bottom: 0;
}

.footer_conatct_item {
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
  p {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-weight: 300;
    white-space: nowrap;
    font-size: 1rem;
  }
  svg {
    min-width: 50px;
    g {
      min-width: 50px;
    }
  }

  text-decoration: none;
  color: #1e1e1e;
}

@media screen and (max-width: 1100px) {
  .footer_conatct_item {
    p {
      white-space: initial;
    }
  }
}
.footer_conatct form {
  input {
    padding: 1rem;
    border: 0;
    background-color: $main_back_color;
    border: 1px solid $back_light;
    width: 100%;
  }
  button {
    padding: 1rem;
    border: 1px solid currentColor;
    background-color: $primary_color;
    color: #fff;
    cursor: pointer;
  }
  h4 {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
}
@media (min-width: 1440px) {
  .footer_top h4 {
    font-size: 1.5vw;
  }
  .footer_top a,
  .footer_top button,
  .footer_top input,
  .footer_top p {
    font-size: 1vw !important;
    line-height: 120%;
  }
}

.footer_links_conatiner {
  display: flex;
  width: 80%;
}
@media (max-width: 600px) {
  .footer_logo_img {
    svg {
      width: 30vw;
    }
  }
  .footer_top {
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
  }
  .footer_larg {
    width: 100%;
  }
  .footer_logo_img p {
    width: 100%;
  }
  .footer_links {
    align-items: flex-start;
  }

  .footer_links_con {
    gap: 1.5rem 1.5rem !important;
  }
  .footer_top h4 {
    margin-bottom: 1rem;
  }
  .footer_conatct form {
    input {
      width: 100%;
    }
    button {
      margin-top: 0.5rem;
    }
  }
  .footer_bottom {
    font-size: 0.7rem;
  }
  .footer_logo {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  .footer_links_conatiner {
    width: 100%;
    flex-direction: column;
    gap: 1.5rem;
  }

  .footer_conatct_item {
    align-items: center;
  }
  .footer_top h4 {
    margin-left: 0;
  }
  .footer_conatct_item svg {
    min-width: 24px;
    max-width: 24px;
  }
}
