@import "./colors.scss";

.players_list {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
  gap: 3rem;
}
.players_item {
  width: 30%;
  min-width: 250px;
  border: 1px solid #e2e2e2;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  align-items: center;
  justify-content: space-around;

  img {
    width: 20%;
    min-width: 50px;
  }

  h3 {
    color: #262626;

    text-align: center;
    font-family: "Metropolis", Arial, Helvetica, sans-serif;
    font-size: 1.1875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 1.9rem */
    letter-spacing: -0.0475rem;
  }

  a {
    color: $primary_color;
    text-align: center;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 1.5rem */
    letter-spacing: -0.0375rem;
    text-decoration: none;

    @extend %center_by_flex;
    gap: 0.5rem;
  }
}
@media (min-width: 1440px) {
  .players_item h3 {
    font-size: 1vw;
  }

  .players_item a {
    font-size: 0.8vw;
  }
}
@media (max-width: 600px) {
  .players_item {
    width: 100%;
  }
}
