@import "./styles/colors.scss";
@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/inter/Inter-Black.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/inter/Inter-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/inter/Inter-ExtraBold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/inter/Inter-ExtraLight-BETA.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/inter/Inter-Thin-BETA.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/inter/Inter-Light-BETA.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/inter/Inter-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/inter/Inter-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/inter/Inter-SemiBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/Metropolis/Metropolis-Black.otf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/Metropolis/Metropolis-Bold.otf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/Metropolis/Metropolis-ExtraBold.otf")
    format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/Metropolis/Metropolis-ExtraLight.otf")
    format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/Metropolis/Metropolis-Light.otf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/Metropolis/Metropolis-Medium.otf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/Metropolis/Metropolis-Thin.otf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/Metropolis/Metropolis-Regular.otf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/Metropolis/Metropolis-SemiBold.otf")
    format("truetype");
  font-display: swap;
}

* {
  box-sizing: border-box;
  margin: 0;
  // scroll-behavior: smooth;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Metropolis", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  min-height: 100vh;
  color: $text_color;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  color: $primary_color;
  font-size: 2.5rem;
  font-weight: 600;
  letter-spacing: -0.0625rem;
  margin-bottom: 3rem;
  font-family: "Metropolis", Arial, Helvetica, sans-serif;
}
// @media (min-width: 1440px) {
//   h2 {
//     font-size: 2.8vw;
//   }
// }
@media (max-width: 900px) {
  h2 {
    font-size: 1.7rem;
  }
}
