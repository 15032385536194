@import "./colors.scss";

.use_cases_list {
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
  margin: auto;
  gap: 4rem;
}
.use_cases_list_item {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: calc(50% - 2rem);
  min-width: 250px;
  p {
    color: #1e1e1e;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.01875rem;
    opacity: 0.7;
  }
  h2 {
    margin: 0;
    color: #000;
    text-transform: capitalize;
    font-size: 1.9rem;
  }

  img {
    width: 100%;
  }

  a {
    color: $primary_color;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

/*-- use_cases_details --*/
.use_cases_details {
  width: 80%;
  margin: auto;
}
.use_cases_details_back {
  margin-bottom: 2rem;
  p {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  a {
    text-decoration: none;
    color: #000;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}
.use_cases_details_back a:hover {
  color: $primary_color;
  transition: 0.2s;
}
.use_cases_details_item {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  p {
    color: #1e1e1e;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.01875rem;
    opacity: 0.7;
  }
  h2 {
    margin: 0;
    color: #000;
    text-transform: capitalize;
  }
}
.use_cases_details_item_img {
  overflow: hidden;
  @extend %center_by_flex;
  max-height: 60vh;

  img {
    width: 100%;
  }
}

.use_cases_slide {
  background-color: $main_back_color;
}
.use_cases_slide_top {
  width: 80%;
  margin: auto;
  text-align: center;
  h2 {
    margin-bottom: 1rem;
  }
  p {
    text-align: center;
  }
}
.use_cases_slide_all {
  display: flex;
  align-items: center;
  justify-content: center;
}
.use_cases_slide_bottom {
  display: flex;
  height: max(300px, 70vh);
  overflow-x: auto;
  margin-top: 2rem;
  padding-bottom: 3rem;
  gap: 2rem;
}
.use_cases_slide_item:nth-child(7) {
  img {
    bottom: 0;
  }
}
.use_cases_slide_item {
  min-width: 80vw;
  overflow: hidden;
  height: 100%;
  @extend %center_by_flex;
  position: relative;

  img {
    width: 100%;
    position: absolute;
    // bottom: 0;
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    @extend %center_by_flex;
    flex-direction: column;
    gap: 2rem;
    background-color: rgba(0, 0, 0, 0.7);
    a {
      text-decoration: none;
      color: #fff;
      padding: 1rem;
      background-color: $primary_color;
    }
    h3 {
      color: #fff;
      text-transform: capitalize;
      font-family: "Metropolis", Arial, Helvetica, sans-serif;
      font-size: 2.625rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2rem;
      letter-spacing: -0.07875rem;
      padding: 0 2rem;
      border-radius: 5px;
    }
    p {
      // opacity: 0;
      width: 50%;
      font-family: "Inter", Arial, Helvetica, sans-serif;
      font-weight: 300;
      text-align: center !important;
    }
  }
}

.use_cases_slide_item:hover {
  div {
    p {
      opacity: 1;
    }
    transition: 0.2s;
  }
}

.use_cases_slide_bottom::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

/* Track */
.use_cases_slide_bottom::-webkit-scrollbar-track {
  background: lightgray;
  border-radius: 10px;
  margin: 40vw;
}

/* Handle */
.use_cases_slide_bottom::-webkit-scrollbar-thumb {
  background: $primary_color;
  border-radius: 10px;
}

/* Handle on hover */
// .use_cases_slide_bottom::-webkit-scrollbar-thumb:hover {
// }

@media (min-width: 1440px) {
  .use_cases_list p,
  .use_cases_list a,
  .use_cases_details_item p,
  .use_cases_details_back a,
  .use_cases_details_back p {
    font-size: 1vw !important;
  }
}
@media (max-width: 600px) {
  .use_cases_list_item {
    width: 100%;
    min-width: 100%;
    h2 {
      font-size: 1.5rem;
    }
  }
  .use_cases_slide_top {
    text-align: left;
  }
  .use_cases_list,
  .use_cases_details,
  .use_cases_slide_top {
    width: 90%;

    h2 {
      margin-top: 2rem;
    }

    p {
      text-align: left;
      margin-bottom: 1rem;
    }
  }
  .use_cases_list_item,
  .use_cases_details_item {
    gap: 1rem;
  }
  .use_cases_details_back a {
    font-size: 0.8rem;
  }
  .use_cases_slide_all {
    flex-direction: column;
    align-items: flex-start;
  }
  .use_cases_slide_bottom {
    margin-top: 0;
  }
  .use_cases_slide_all a {
    margin-top: 1rem;
    padding: 0.5rem 2rem;
  }
  .use_cases_slide_item div {
    gap: 1rem;
  }
  .use_cases_slide_item div h3 {
    font-size: 1.5rem;
  }
  .use_cases_slide_item div p {
    font-size: 1rem;
    width: 80%;
  }
  .use_cases_slide_bottom {
    padding-bottom: 1rem;
    height: 300px;
  }
  .use_cases_slide_item div a {
    padding: 0.5rem 2rem;
  }
  /* Track */
  .use_cases_slide_bottom::-webkit-scrollbar-track {
    margin: 20vw;
  }
}
